
MAIN {

    padding: 50px 0;
    padding-bottom: $footer-height + 20px;
    min-width: $min-width;
    max-width: $max-width;
    margin-left: auto;
    margin-right: auto;
    padding-left: $main-padding-h;
    padding-right: $main-padding-h;
    text-align: center;

    H1 {
        font-family: 'Montserrat', sans-serif;
        font-weight: 300;
        text-transform: uppercase;
        color: $primary-text-color;
    }

    >H1 {
        font-size: 48px;
        margin-bottom: 20px;
    }

    H2,
    H3 {
        font-family: 'Raleway', sans-serif;
        font-weight: 400;
        color: $secondary-text-color;
    }

    >H2 {
        font-size: 21px;
    }

    >H3 {
        font-size: 21px;
        font-weight: 200;
    }

    H4 {
        font-family: 'Raleway', sans-serif;
        font-weight: 500;
        color: $secondary-text-color;
    }

    >H4 {
        font-size: 14px;
    }

    P {
        color: $secondary-text-color;
        font-size: 14px;
        line-height: 20px;
    }
}


@mixin banded-header() {
    position: relative;
    padding: 2px 10px 2px 10px;
    background-color: #E0E0E0;
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    font-weight: 400;
    text-transform: uppercase;
    text-align: justify;
    color: $primary-text-color;
}


