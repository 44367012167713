@import '../../Styles/variables.scss';
@import '../../Styles/main.scss';

$image-frame-width: 8px;
$image-small-width: 350px;
$image-big-width: 600px;
$article-small-width: $image-small-width + ($image-frame-width * 2);
$article-big-width: $image-big-width + ($image-frame-width * 2);


#about {
  margin: 30px auto 50px auto;
  padding-top: 20px;

  .slider {
    margin: 0 50px;

    .slick-slide {
      img {
        height: 300px; // Imposta l'altezza desiderata

        object-fit: cover; // Mantiene le proporzioni dell'immagine
        padding: 10px;
      }
    }

    .slick-slider {

      .slick-prev,
      .slick-next {
        &::before {
          font-size: 30px;
          color: black;
        }
      }
    }
  }

  .grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    /* Three equal columns */
    gap: 10px;
    /* Optional: space between columns */
    @media screen and (max-width: $max-width) {
      grid-template-columns: 1fr;
      /* One column */
    }
  }

  .grid-column {
    padding: 20px;
    /* Optional: column padding */
  }
  .lists {
    display: flex;
    justify-content: center;
    flex-direction: row;
    @media screen and (max-width: $max-width) {
        flex-direction: column;
    }
    $spacing-v: 15px;
    $spacing-h: 20px;
    &:not(:first-child) {
        margin-top: $spacing-v;
    }
    ARTICLE {
        &:not(:first-child) {
            margin-top: $spacing-v;
        }
        @media screen and (min-width: $max-width) {
            width: 100%;
            margin-top: 0 !important;
            &:not(:first-child) {
                margin-left: $spacing-h;
            }
        }
        text-align: justify;
        H1 {
            @include banded-header();
        }
        LI {
            position: relative;
            list-style: none;
            padding-left: 15px;
            $margin-v: 10px;
            margin: $margin-v 0 $margin-v 0;
            font-size: 14px;
            line-height: 20px;
            color: $secondary-text-color;
            &:before {
                position: absolute;
                left: 0;
                font-family: 'icons';
                content: '\e812';
            }
        }
        P {
            margin-top: 10px;
        }
    }
}

}