@import '../../Styles/variables.scss';

.spacing {
    height: 40px;
    flex-grow: 2;
}



#homepage {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    align-content: stretch;

    #logo {
        position: absolute;
        /* Posizionamento fisso rispetto alla finestra del browser */
        top: 50%;
        /* Posizionamento verticale al 50% */
        left: 50%;
        /* Posizionamento orizzontale al 50% */
        transform: translate(-50%, -100%);
        /* Centra il div rispetto alle sue dimensioni */
        text-align: center;
        z-index: 2;
    }

    #slider {
        z-index: 1;

    }


    H1,
    H2 {
        color: #FFFF00;
        text-shadow: 4px 4px 6px #000000;
    }

    H1 {
        margin-bottom: 30px;
        font-family: 'Channel';
        font-size: 48px;
        line-height: 50px;
        font-weight: bold;
    }

    H2 {
        margin-bottom: 20px;
        font-family: 'Gabrielle';
        font-size: 50px;
        line-height: 52px;
        font-weight: normal;
    }

    @media screen and (max-width: 450px) {
        H1 {
            font-size: 23px;
            line-height: 32px;
            text-shadow: 2px 2px 12px #00000087;

        }

        H2 {
            font-size: 33px;
            line-height: 35px;
            text-shadow: 2px 2px 3px #000000;

        }
    }

}