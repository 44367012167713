@import "../../styles/variables.scss";

#reach {
    > H1 {
        margin-bottom: 5px;
    }
    > H2 {
        font-weight: bold;
        margin-bottom: 5px;
    }
    > H3 {
        margin-bottom: 10px;
    }
    > H4 {
        margin-bottom: 20px;
        A {
            color: $secondary-text-color;
            &:hover {
                color: $primary-text-color;
            }
        }
    }
}


.mapContainer {
    width: 100%;
    height: 300px;
}

#reachus {
    border-top: 1px solid #CCCCCC;
    margin-top: 20px;
    padding-top: 30px;
    H1 {
        padding-right: 40px;
        > I {
            position: absolute;
            right: 5px;
        }
    }
}

.leaflet-top, .leaflet-bottom {
    z-index: 800; //hide leaflet zoom control under the header
}