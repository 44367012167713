$primary-text-color:   #444444;
$secondary-text-color: #666666;

$main-padding-h: 20px;


$strip-background-color: rgba(255, 255, 255, 0.9);
$header-height: 32px;
$footer-height: 40px;

$header-big-width: 800px;
$header-int-width: 460px;

$social-color: #CCCCCC;
$min-height: 500px;

$homepage-portrait-max-width: 1023px;

$min-width: 320px;
$max-width: 1300px;

$max-text-width: 1000px;

$max-content-width: 1150px;

$main-text-font: 'Raleway', sans-serif;

$gallery-chooser-size: 250px;
$gallery-chooser-max-size: 20%;

