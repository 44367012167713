@import '../../Styles/variables.scss';
@import '../../Styles/mixin.scss';
@import '../../Styles/main.scss';

MAIN#prices {
    > H2 {
        @include banded-header();
    }
}

TABLE {
    width: 100%;
    font-size: 14px;
    border-collapse: collapse;
    margin-top: 10px;
    margin-bottom: 10px;
    THEAD {
        color: $primary-text-color;
    }
    TBODY {
        color: $secondary-text-color;
        TR {
            border-top: 1px solid #DDDDDD;
            &:first-child {
                border-width: 2px;
            }
        }
    }
    TH, TD {
        padding: 10px;;
        &:first-child {
            text-align: left;
        }
    }
}

#howto {
    border-top: 1px solid #CCCCCC;
    padding-top: 30px;
    margin-top: 20px;
}