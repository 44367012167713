footer {
    margin-top: 20px;
    padding: 30px;
    text-align: center;
    font-size: 14px;
    line-height: 20px;
    color: #666666;

    address {
        font-style: normal;
    }

    a {
        color: #000000;
        transition: all 0.4s ease 0s;

        &:hover {
            text-decoration: underline;
            color: #2A6496;
        }
    }
}