@import 'icons.scss';
@import './_variables';
@import './fonts';
@import './_mixin';

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Raleway', sans-serif;
}

html {
    position: relative;
    height: 100%;
}

body {
    position: relative;
    color: $primary-text-color;
}

a {
    text-decoration: none;
    color: $primary-text-color;
}

.container {
    &:nth-child(odd) {
      background-color: #efefef;
    }
  }

DIV.info {
    color: #FFFFFF;
    padding: 15px 35px;
    text-align: center;
    font-size: 14px;

    &.blue {
        background-color: #3498DB;
    }

    &.red {
        background-color: #FF0000;
    }
}

.lists {
    display: flex;
    justify-content: center;
    flex-direction: row;

    @media screen and (max-width: $max-width) {
        flex-direction: column;
    }

    $spacing-v: 15px;
    $spacing-h: 20px;

    &:not(:first-child) {
        margin-top: $spacing-v;
    }

    ARTICLE {
        &:not(:first-child) {
            margin-top: $spacing-v;
        }

        @media screen and (min-width: $max-width) {
            width: 100%;
            margin-top: 0 !important;

            &:not(:first-child) {
                margin-left: $spacing-h;
            }
        }

        text-align: justify;

        H1 {
            @include banded-header();
        }

        LI {
            position: relative;
            list-style: none;
            padding-left: 15px;
            $margin-v: 10px;
            margin: $margin-v 0 $margin-v 0;
            font-size: 14px;
            line-height: 20px;
            color: $secondary-text-color;

            &:before {
                position: absolute;
                left: 0;
                font-family: 'icons';
                content: '\e812';
            }
        }

        P {
            margin-top: 10px;
        }
    }
}