@import '_variables';

@mixin strip($height) {
    position: fixed;
    z-index: 1000;
    width: 100%;
    min-width: $min-width;// + ($main-padding-h*2);

    line-height: $height;
    overflow-y: hidden;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.4);
    background-color: $strip-background-color;
    SPAN, A {
        padding: 0 10px 0 10px;
    }
    I {
        padding-right: 10px;
    }
}

@mixin banded-header() {
    position: relative;
    padding: 2px 10px 2px 10px;
    background-color: #E0E0E0;
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    font-weight: 400;
    text-transform: uppercase;
    text-align: justify;
    color: $primary-text-color;
}