@import './styles/variables.scss';

html, body {
  margin: 0;
  padding: 0;
  
}

#app-container {
  display: flex;
  flex-direction: column;
/*   min-height: 100vh;
 */
}


#main-content {
  position: relative;
  

}

.page-content {
  min-width: 420px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.full-page-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.85); /* red with 50% opacity */
  z-index: -1; /* put the overlay behind the content */

}

.CookieConsent {
  margin-bottom: $footer-height;
}