@import '../Styles/variables.scss';
@import '../Styles/mixin.scss';

.header {
    @include strip($header-height);
    top: 0;
    left: 0;
    font-size: 0.8em;
    font-weight: 300;
    transition: all 0.4s ease 0s;
    A {
        transition: text-shadow 0.3s ease-in-out 0s;
        &:hover {
            text-shadow: 0 0 10px $secondary-text-color;
        }
    }
    .contacts {
        display: inline-block;
        // animation start "transition" with animation or w/e?
        @media screen and (max-width: $header-big-width) {
            SPAN:first-child {
                display: none;
            }
        }
        @media screen and (max-width: $header-int-width) {
            SPAN:nth-child(2) {
                display: visible;
            }
        }
    }
    .lang {
        position: absolute;
        height: 100%;
        $spacing: 2px;
        top: $spacing;
        right: $spacing;
        transition: all 0.4s ease 0s;
        font-size: 0;
        div {
            cursor: pointer;
            display: inline-block;
            $size: $header-height - ($spacing * 2);
            width: $size;
            height: $size;
            border-radius: 50%;
            margin-right: 4px;
            padding: 0;
            color: #111111;
            text-align: center;
            line-height: $size;
            font-size: 12px;
            transition: all 0.4s ease 0s;
            &:hover, &.active {
                background-color: rgba(0, 0, 0, 0.6);
                color: #FFFFFF;
            }
        }
    }
    SPAN:not(:last-child) {
        border-right: 1px dotted rgba(0, 0, 0, 0.3);
    }

    .notification-stripe {
        display: flex;
        align-items: center; /* This will vertically center the text */
        justify-content: center; /* This will horizontally center the text */
        background-color: rgb(255, 0, 0, 0.9);
        color: white;
        height: 30px;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.4);
      }
}